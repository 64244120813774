@import url('https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300&display=swap');



body {
  margin: 0;
  font-family: 'Libre Franklin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url('./assets//images/bg-filtered.png') fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

code {
  font-family: 'Libre Franklin', sans-serif;
}