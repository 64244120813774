@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:wght@300&display=swap");

.header {
  background-color: #292128a1;
  font-family: "Libre Franklin", sans-serif;
  .header-link {
    color: #e9c46a;
    font-weight: 600;
    // color: #E9C46A;
  }
  .header-collapse {
    display: flex;
  }
  .toggle {
    background-color: #e9c46a;
  }
}

@media (max-width: 343px) {
  .header {
    .header-collapse {
      display: initial;
    }
  }
}

.footer {
  font-family: "Libre Franklin", sans-serif;
  align-items: center;
  background-color: #000000ab;
  display: flex;
  width: 100%;
  .text {
    color: #d9dbe1;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }
}

.home-content {
  font-family: "Libre Franklin", sans-serif;
  margin-bottom: 100px;
  .home-content-text {
    font-size: 24px;
    padding: 0px 50px;
    background-color: #8f794257;
    border: 1px solid;
    border-color: #b278cd;
    border-radius: 20px;
  }
}

@media (max-width: 768px) {
  .home-content {
    .home-content-text {
      font-size: 18px;
      padding: 0px 10px;
    }
  }
}

.service-head {
  font-family: "Libre Franklin", sans-serif;
  margin-bottom: 40px;
}
.service-content {
  font-family: "Libre Franklin", sans-serif;
  background-color: #8f794257;
}

.card {
  background-color: rgba(0, 0, 0, 0.445);
  .card-text {
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  .card {
    .card-text {
      font-size: 18px;
    }
  }
}

.scard {
  background-color: var(--background);
  display: block;
  max-width: 200px;
  min-height: 90px;
  cursor: pointer;
  border: 3px solid var(--primary);
  box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--green),
    20px -20px 0 -3px var(--background), 20px -20px var(--yellow),
    30px -30px 0 -3px var(--background), 30px -30px var(--orange),
    40px -40px 0 -3px var(--background), 40px -40px var(--red);
}

.scard:hover {
  animation: shadow-wave 1s ease infinite;
}

@keyframes shadow-wave {
  0% {
    border: 3px solid var(--primary);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--green),
      20px -20px 0 -3px var(--background), 20px -20px var(--yellow),
      30px -30px 0 -3px var(--background), 30px -30px var(--orange),
      40px -40px 0 -3px var(--background), 40px -40px var(--red);
  }
  20% {
    border: 3px solid var(--red);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--primary),
      20px -20px 0 -3px var(--background), 20px -20px var(--green),
      30px -30px 0 -3px var(--background), 30px -30px var(--yellow),
      40px -40px 0 -3px var(--background), 40px -40px var(--orange);
  }
  40% {
    border: 3px solid var(--orange);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--red),
      20px -20px 0 -3px var(--background), 20px -20px var(--primary),
      30px -30px 0 -3px var(--background), 30px -30px var(--green),
      40px -40px 0 -3px var(--background), 40px -40px var(--yellow);
  }
  60% {
    border: 3px solid var(--yellow);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--orange),
      20px -20px 0 -3px var(--background), 20px -20px var(--red),
      30px -30px 0 -3px var(--background), 30px -30px var(--primary),
      40px -40px 0 -3px var(--background), 40px -40px var(--green);
  }
  80% {
    border: 3px solid var(--green);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--yellow),
      20px -20px 0 -3px var(--background), 20px -20px var(--orange),
      30px -30px 0 -3px var(--background), 30px -30px var(--red),
      40px -40px 0 -3px var(--background), 40px -40px var(--primary);
  }
  100% {
    border: 3px solid var(--primary);
    box-shadow: 10px -10px 0 -3px var(--background), 10px -10px var(--green),
      20px -20px 0 -3px var(--background), 20px -20px var(--yellow),
      30px -30px 0 -3px var(--background), 30px -30px var(--orange),
      40px -40px 0 -3px var(--background), 40px -40px var(--red);
  }
}

:root {
  --primary: #22d2a0;
  --secondary: #192824;
  --background: #192824;
  --green: #1fc11b;
  --yellow: #ffd913;
  --orange: #ff9c55;
  --red: #ff5555;
}

.gradient-border {
  --border-width: 3px;
  position: relative;
  justify-content: center;
  align-items: center;
  // width: 100%;
  background: #222;
  border-radius: var(--border-width);

  &::after {
    position: absolute;
    content: "";
    top: calc(-1 * var(--border-width));
    left: calc(-1 * var(--border-width));
    z-index: -1;
    width: calc(100% + var(--border-width) * 2);
    height: calc(100% + var(--border-width) * 2);
    background: linear-gradient(
      60deg,
      hsl(224, 85%, 66%),
      hsl(269, 85%, 66%),
      hsl(314, 85%, 66%),
      hsl(359, 85%, 66%),
      hsl(44, 85%, 66%),
      hsl(89, 85%, 66%),
      hsl(134, 85%, 66%),
      hsl(179, 85%, 66%)
    );
    background-size: 300% 300%;
    background-position: 0 50%;
    border-radius: calc(2 * var(--border-width));
    animation: moveGradient 4s alternate infinite;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
